<template>
  <div class="body-1">
    <v-card>
      <v-card-title>
        Configuration List
        <v-spacer></v-spacer>
        <v-text-field
          v-if="subscription.isActive"
          v-model="search"
          append-icon="mdi-search"
          label="Search"
          single-line
          hide-details
        ></v-text-field>
      </v-card-title>

      <v-data-table
        v-if="subscription.isActive"
        :headers="headers"
        :items="configurationList"
        :loading="isLoadingConfigurationList"
        :items-per-page="rowsPerPage"
        :footer-props="{itemsPerPageOptions: itemsPerPageOption}"
        :sort-by="sortBy"
        :search="search"
        class="elevation-1"
      >
        <template v-slot:item.configurationName="{item}">
          <router-link
            :to="{name: 'configuration', 
            params: { configId: item.configurationId}, 
            query: { accessKey: account.externalAccessToken}}"
          >{{item.configurationName}}</router-link>
        </template>
        <template v-slot:no-data>
          <td>No data found</td>
        </template>
      </v-data-table>
      <v-card-text v-else>
        <div
          v-if="newSubscription"
        >Congrats on your new LiveCad subscription. We're currently waiting for everything to go through! Feel free to refresh the view.</div>
        <div v-else>
          You do not have a current LiveCad subscription.
          <router-link :to="{ name: 'account'}">Click here to review</router-link>
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";

let headers = [
  {
    text: "State",
    value: "state",
    sortable: true
  },
  {
    text: "County",
    value: "county",
    sortable: true
  },
  {
    text: "Jursidiction",
    value: "jursidiction",
    sortable: true
  },
  {
    text: "Configuration Name",
    value: "configurationName",
    sortable: true
  }
];

export default {
  data() {
    return {
      search: "",
      headers: headers,
      rowsPerPage: 50,
      sortBy: "configurationName",
      itemsPerPageOption: [25, 50, 100, 250, 500],
      newSubscription: false
    };
  },
  computed: mapState({
    configurationList: state => state.configurationList,
    isLoadingConfigurationList: state => state.isLoadingConfigurationList,
    subscription: state => state.subscription,
    account: state => state.account
  }),
  methods: {
    ...mapActions(["loadConfigurationList", "loadSubscription", "loadAccount"])
  },
  created: function() {
    this.newSubscription = this.$route.query.newSubscription ? true : false;

    if (this.newSubscription) {
      sessionStorage.setItem("NEW_SUBSCRIPTION", "true");
    } else {
      var ns = sessionStorage.getItem("NEW_SUBSCRIPTION");
      if (ns) this.newSubscription = true;
    }

    this.loadConfigurationList();
    this.loadSubscription();
    this.loadAccount();
    this.$syncHub.start();
  }
};
</script>