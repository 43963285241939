<template>
  <div>
    <h1>Welcome to LiveCad 3.0!</h1>

    <HomeUnauthenticated />
  </div>
</template>

<script>
import HomeUnauthenticated from "./HomeUnauthenticated";

export default {
  components: {
    HomeUnauthenticated
  }
};
</script>