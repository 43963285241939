import decode from 'jwt-decode';
import axios from 'axios';

const ID_TOKEN_KEY = "access_token";
const LOGGED_IN_AS_KEY = "logged_in_as";

export function requireAuth(to, from, next) {
    if (!isLoggedIn()) {
        var accessKey = to.query.accessKey;
        if (accessKey && accessKey.trim() !== '') {
            var api = "/authenticateKeyless";
            axios
                .post(api, {
                    accessKey: accessKey
                })
                .then(
                    res => {
                        setIdToken(res.data);
                        next();
                    },
                    () => {
                        next({
                            name: 'login'
                        });
                    }
                );

        } else {
            next({
                name: 'login',
                query: { redirect: to.fullPath }
            });
        }
    } else {
        next();
    }
}

export function requireAdminAuth(to, from, next) {
    var api = "/api/admin/allowed";
    var token = getIdToken();

    if (isLoggedIn()) {
        axios
            .get(api, {
                headers: { 'Authorization': "bearer " + token },
            })
            .then(
                () => {
                    next();
                },
                () => {
                    next({
                        name: 'configurationList'
                    });
                }
            );
    } else {
        next({
            name: 'login',
            query: { redirect: to.fullPath }
        });
    }
}

export function loggedInRedirect(to, from, next) {
    if (isLoggedIn()) {
        next({
            name: 'configurationList'
        });
    } else {
        next();
    }
}

export function getIdToken() {
    return localStorage.getItem(ID_TOKEN_KEY);
}

export function getLoggedInAs() {
    if (isLoggedIn()) {
        return localStorage.getItem(LOGGED_IN_AS_KEY);
    } else {
        localStorage.removeItem(ID_TOKEN_KEY);
        localStorage.removeItem(LOGGED_IN_AS_KEY);
        return undefined;
    }
}

export function setIdToken(token) {
    localStorage.setItem(ID_TOKEN_KEY, token.jwt);
    localStorage.setItem(LOGGED_IN_AS_KEY, token.emailAddress);
}

export function isLoggedIn() {
    const idToken = getIdToken();
    return !!idToken && !isTokenExpired(idToken);
}

export function logOut() {
    localStorage.removeItem(ID_TOKEN_KEY);
    localStorage.removeItem(LOGGED_IN_AS_KEY);
}

function getTokenExpirationDate(encodedToken) {
    const token = decode(encodedToken);
    if (!token.exp) { return null; }

    const date = new Date(0);
    date.setUTCSeconds(token.exp);

    return date;
}

function isTokenExpired(token) {
    const expirationDate = getTokenExpirationDate(token);
    return expirationDate < new Date();
}