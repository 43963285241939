<template>
  <v-app>
    <v-app-bar app>
      <v-toolbar-title class="headline text-uppercase">
        <span>LiveCad</span>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn v-if="loggedInAs === undefined" text to="/login">
        <span class="mr-2">Login</span>
      </v-btn>
      <v-toolbar-items v-else>
        <v-btn text to="/">Configurations</v-btn>
        <v-menu v-if="account.isAdmin">
          <template v-slot:activator="{ on }">
            <v-btn text v-on="on">
              <span>Admin</span>
              <v-icon dark>mdi-menu-down</v-icon>
            </v-btn>
          </template>

          <v-list>
            <v-list-item @click="goToView('adminMenu')">
              <v-list-item-title>Admin Dashboard</v-list-item-title>
            </v-list-item>
            <v-list-item @click="goToView('adminConfigurationList')">
              <v-list-item-title>Configurations</v-list-item-title>
            </v-list-item>
            <v-list-item @click="goToView('adminUserList')">
              <v-list-item-title>Users</v-list-item-title>
            </v-list-item>
            <v-list-item @click="goToView('adminAgencyList')">
              <v-list-item-title>Agencies</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
        <v-menu>
          <template v-slot:activator="{ on }">
            <v-btn text v-on="on">
              <span>{{loggedInAs}}</span>
              <v-icon dark>mdi-menu-down</v-icon>
            </v-btn>
          </template>

          <v-list>
            <v-list-item @click="goToView('account')">
              <v-list-item-title>My Account</v-list-item-title>
            </v-list-item>
            <v-list-item @click="goToView('webhooks')">
              <v-list-item-title>Webhooks</v-list-item-title>
            </v-list-item>
            <v-list-item @click="goToView('terms')">
              <v-list-item-title>Terms and Conditions</v-list-item-title>
            </v-list-item>
            <v-list-item @click="goToView('logout')">
              <v-list-item-title>Log Out</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-toolbar-items>
    </v-app-bar>

    <v-main>
      <v-container>
        <slot />
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import { mapState, mapActions } from "vuex";

export default {
  // <!-- <meta name="viewport" content="width=device-width,initial-scale=1.0"> -->
  metaInfo: {
    meta: [
      { name: "viewport", content: "width=device-width, initial-scale=1.0" }
    ]
  },
  computed: mapState({
    loggedInAs: state => state.loggedInAs,
    account: state => state.account
  }),
  methods: {
    ...mapActions(["loadAccount"]),
    goToView(viewName) {
      this.$router.push({ name: viewName });
    }
  }
};
</script>