<template>
  <div>
    <slot/>
  </div>
</template>

<script>

module.exports = {
    metaInfo: {
    meta: [{ name: "viewport", content: "width=1024"}]
  },
}
</script>