<template>
  <div></div>
</template>

<script>
import { logOut } from "../auth";

export default {
  data() {
    return {};
  },
  beforeMount() {
    logOut();
    this.$store.state.loggedInAs = undefined;
    this.$router.push({ name: "home" });
  }
};
</script>

